<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="card border rounded">
          <div class="card-body">
            <b-row>
              <b-col lg="11">
                <h3 class="my-3 mx-3 text-center">Sacar via PIX</h3>
              </b-col>
              <b-col lg="1" class="py-3 d-none d-lg-block">
                <router-link :to="{ name: 'home' }" class="link">
                  <h3><i class="mdi mdi-arrow-left"></i></h3>
                </router-link>
              </b-col>
            </b-row>
            <hr>
            <b-alert
              :show="!checkIfAbleToWithdraw()"
              variant="danger"
              class="d-md-flex text-center justify-content-md-between"
            >
              <span>
                <i class="fa fa-warning mr-md-2" />
                Estão faltando algumas informações para que você possa efetuar o saque.
              </span>
              <b-button
                variant="link"
                class="p-md-0 pb-0 alert-link"
                v-b-modal.lacking_info_modal
              >
                Saiba mais
              </b-button>
            </b-alert>
            <b-alert
              :show="checkIfAbleToWithdraw() && !isBankDataConfirmed"
              variant="danger"
              class="d-md-flex text-center justify-content-md-between"
            >
              <span>
                <i class="fa fa-warning mr-md-2" />
                Seus dados bancários ainda não foram confirmados ou estão inválidos.
              </span>
              <b-button
                variant="link"
                class="p-md-0 pb-0 alert-link"
                @click="goToProfile"
              >
                Ir ao perfil
              </b-button>
            </b-alert>
          </div>
          <plg-payment-amount-form
            :disableFields="!checkIfAbleToWithdraw() || !isBankDataConfirmed"
            :isLoading="isLoading"
            @formSubmit="confirmInfo"
          />
        </div>
      </div>
    </div>
    <b-modal id="lacking_info_modal" title="Atualizar Informações" centered hide-header-close>
      Precisamos que você nos forneça os seguintes dados para que possamos realizar o seu saque:
      <ul class="m-2">
        <li>Código do Banco</li>
        <li>Agência</li>
        <li>Conta</li>
      </ul>
      Você pode informá-los no seu perfil de usuário.
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel" class="text-white">Cancelar</b-button>
        <b-button @click="goToProfile" variant="primary">Ir Para o Perfil</b-button>
      </template>
    </b-modal>
    <b-modal id="confirm_info_modal" title="Confirmar Dados Bancários" centered hide-header-close>
      Por favor, cheque se os dados bancários a seguir estão corretos:
      <ul class="m-2">
        <li>Código do Banco: <b>{{ userBankCode }}</b></li>
        <li>Agência: <b>{{ user.branch_code }}<span v-if="user.branch_code_digit">-{{ user.branch_code_digit }}</span></b></li>
        <li>Conta: <b>{{ user.account_number }}<span v-if="user.account_number_digit">-{{ user.account_number_digit }}</span></b></li>
      </ul>
      Caso haja qualquer erro, favor corrigir as informações em seu perfil.
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel" class="text-white">Cancelar</b-button>
        <b-button @click="withdraw" variant="success">Meus Dados Estão Corretos</b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import PlgPaymentAmountForm from "../../components/payments/PlgPaymentAmountForm.vue";
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import PlgBankServicesApi from "../../apis/PlgBankServicesApi.vue";
import ispbOptions from "../../helpers/users-opts/ispbOptions";
import Toasts from "../../components/Toasts.vue";

export default {
  name: "PlgPixWithdraw",
  data() {
    return {
      isLoading: false,
      user: {},
      amount: 0,
      ispbOptions: ispbOptions
    }
  },
  mixins: [
    PlgUsersApi,
    PlgBankServicesApi,
    Toasts
  ],
  computed: {
    userBankCode() {
      if (this.user.bank_code) {
        let bankData = ispbOptions.find(bank => bank["ispb"] == this.user.bank_code);
        return bankData["codeNumber"];
      }
      return null;
    },
    isBankDataConfirmed() {
      return this.user.is_bank_data_confirmed
    }
  },
  components: {PlgPaymentAmountForm},
  methods: {
    checkIfAbleToWithdraw() {
      return !!this.user.branch_code &&
             !!this.user.bank_code &&
             !!this.user.account_number;
    },
    goToProfile() {
      this.$router.replace({name: 'user_profile', hash: '#bank-data'});
    },
    confirmInfo($event) {
      this.amount = $event * 100;
      this.$bvModal.show("confirm_info_modal");
    },
    async withdraw() {
      this.$bvModal.hide("confirm_info_modal");
      const response = await this.withdrawAmount(this.amount);
      if (response.status === 200) {
        this.$router.replace({name: "pix_withdraw_complete"})
      } else {
        this.showToast(
          response.data.status,
          response.data.message
        )
      }
    }
  },
  async mounted() {
    const response = await this.getCurrentUser();
    this.user = response.data;
  }
};
</script>

<style scoped>
.link {
  color: black;
}
</style>